<template>
  <div class="winery-share">

    <div class="connect-cta">
      <button type="button" @click="connect" class="btn-white px-4 my-2" v-if="$auth.isAuthenticated">Connect with us</button>
      <button type="button" @click="showPopup" class="btn-white px-4 my-2" v-if="!$auth.isAuthenticated">
          Connect with us
          <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path d="M9.875 5.75H9.3125V4.0625C9.3125 2.11719 7.69531 0.5 5.75 0.5C3.78125 0.5 2.1875 2.11719 2.1875 4.0625V5.75H1.625C0.992188 5.75 0.5 6.26562 0.5 6.875V11.375C0.5 12.0078 0.992188 12.5 1.625 12.5H9.875C10.4844 12.5 11 12.0078 11 11.375V6.875C11 6.26562 10.4844 5.75 9.875 5.75ZM7.4375 5.75H4.0625V4.0625C4.0625 3.14844 4.8125 2.375 5.75 2.375C6.66406 2.375 7.4375 3.14844 7.4375 4.0625V5.75Z" fill="#E8490F"/>
          </svg>
      </button>
<!--      <a :href="`/explore?winery=${winery.elements.winery_id.value}`" class="btn-red-outline px-4 my-2" v-if="winery.elements.winery_id.value">View on map</a>-->
    </div>

    <div class="availability mt-12">
      <div class="opacity-50">Available for</div>
      <ul class="space-y-4 mt-4">
        <li class="flex flex-row leading-2">
          <svg class="block flex-shrink-0 mr-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="12.053" height="9.548" viewBox="0 0 12.053 9.548">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,4.393,3.711,8,11,0" transform="translate(0.511 0.494)" fill="none" stroke="#E8490F" stroke-miterlimit="10" stroke-width="1.467"/>
          </svg>
          <div>Book a meeting</div>
        </li>
        <li class="flex flex-row leading-2">
          <svg class="block flex-shrink-0 mr-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="12.053" height="9.548" viewBox="0 0 12.053 9.548">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,4.393,3.711,8,11,0" transform="translate(0.511 0.494)" fill="none" stroke="#E8490F" stroke-miterlimit="10" stroke-width="1.467"/>
          </svg>
          <div>Importer/Distributor details</div>
        </li>
        <li class="flex flex-row leading-2">
          <svg class="block flex-shrink-0 mr-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="12.053" height="9.548" viewBox="0 0 12.053 9.548">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,4.393,3.711,8,11,0" transform="translate(0.511 0.494)" fill="none" stroke="#E8490F" stroke-miterlimit="10" stroke-width="1.467"/>
          </svg>
          <div>Product Information</div>
        </li>
        <li class="flex flex-row leading-2">
          <svg class="block flex-shrink-0 mr-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="12.053" height="9.548" viewBox="0 0 12.053 9.548">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,4.393,3.711,8,11,0" transform="translate(0.511 0.494)" fill="none" stroke="#E8490F" stroke-miterlimit="10" stroke-width="1.467"/>
          </svg>
          <div>Price List/FOB details</div>
        </li>
      </ul>
    </div>

    <div class="website mt-12" v-if="winery.elements.winery___company_website.value">
      <div class="opacity-50">Website</div>
      <div class="mt-2">
        <a :href="websiteUrl" target="_blank" class="transition-colors hover:text-primary-500">{{ winery.elements.winery___company_website.value }}</a>
      </div>
    </div>

    <div class="phone-number mt-12" v-if="winery.elements.winery___company_phone.value">
      <div class="opacity-50">Contact number</div>
      <div class="mt-2">
        <a :href="`tel:${winery.elements.winery___company_phone.value}`" target="_blank" class="transition-colors hover:text-primary-500">{{ winery.elements.winery___company_phone.value }}</a>
      </div>
    </div>

    <div class="social-links mt-12">
      <div class="opacity-50">Social</div>
      <social-icons :facebook="winery.elements.facebook.value" :twitter="winery.elements.twitter.value" :instagram="winery.elements.instagram.value" :linkedin="winery.elements.linkedin.value" />
    </div>

<!--    <button type="button" @click="showAlpaca" class="mt-12 focus:outline-none" v-if="winery.elements.winery_id.value && showMap">View on map</button>-->
    <button type="button" @click="showMap" class="mt-12 focus:outline-none" v-if="winery.elements.latitude.value && winery.elements.longitude.value">View on map</button>

  </div>
</template>

<script>
import SocialIcons from "@/components/SocialIcons";
import Bus from '@/events/EventBus';
export default {
  name: 'WineryShare',
  components: {SocialIcons},
    data () {
      return {
          showMapPopup: false
      }
    },
  props: {
    winery: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    connect () {
      Bus.$emit('winery-connect')
    },
    showAlpaca() {
      this.$emit('show-alpaca')
    },
    showPopup() {
        Bus.$emit('show-locked-content-popup')
    },
    showMap() {
        Bus.$emit('show-winery-map', this.winery)
    }
  },
  computed: {
    websiteUrl () {
      if (this.winery.elements.winery___company_website.value) {
        if (!/^https?:\/\//i.test(this.winery.elements.winery___company_website.value)) {
          return 'http://' + this.winery.elements.winery___company_website.value
        }
        return this.winery.elements.winery___company_website.value
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">

  .connect-cta {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #121212;
    z-index: 100;
    padding: 20px;
    text-align: center;
    border-top: 1px solid rgba(#FFF, 0.1);

    button, a {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      svg {
        margin-left: 10px;
      }
    }

    @media(min-width: 768px)
    {
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      padding: 0;
      text-align: left;
      border: none;

      button, a {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
      }
    }
  }
</style>
