<template>
  <div class="winery-connect" :class="[{'active': active}]">
    <div class="overlay" @click="hide"></div>
    <div class="panel bg-grey-100">
      <div class="close text-right mb-8 cursor-pointer">
        <svg @click="hide" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.165 29.165" class="stroke-current inline-block hover:text-grey-500">
          <g id="X" transform="translate(0.582 0.582)">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,0,28,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>
            <path id="Stroke_3" data-name="Stroke 3" d="M28,0,0,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>
          </g>
        </svg>
      </div>
      <div class="winery-name text-grey-500">{{ winery.elements.name.value }}</div>
      <div class="heading">Connect<br>with us</div>
      <div class="intro text-grey-500">We’d love to talk to you about our winery and wines! Simply complete the form below and we’ll be in touch to start a conversation.</div>
      <form @submit.prevent="submitForm">
        <div class="form-heading">Your enquiry type</div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.priceListFobDetails">
              <div class="filler"></div>
            </div>
            <div class="select-none">Price list / FOB details</div>
          </label>
        </div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.importerDistributerDetails">
              <div class="filler"></div>
            </div>
            <div class="select-none">Importer / Distributor details</div>
          </label>
        </div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.bookAnAppointment">
              <div class="filler"></div>
            </div>
            <div class="select-none">Book a meeting</div>
          </label>
        </div>
        <div class="field" v-show="form.bookAnAppointment">
          <datetime
              v-model="form.preferredDate"
              :min-datetime="tomorrow"
              placeholder="Preferred date"
              @input="$v.form.preferredDate.$touch"
              :class="[{ 'error': $v.form.preferredDate.$invalid && $v.form.preferredDate.$dirty }, { 'success': $v.form.preferredDate.$dirty && !$v.form.preferredDate.$invalid }]"
          ></datetime>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.preferredDate.required && $v.form.preferredDate.$dirty">Preferred date is required</div>
        </div>
        <div class="field" v-show="form.bookAnAppointment">
          <select
              class="input-text-connect"
              v-model="form.preferredTime"
              @change="$v.form.preferredTime.$touch"
              :class="[{ 'error': $v.form.preferredTime.$invalid && $v.form.preferredTime.$dirty }, { 'success': $v.form.preferredTime.$dirty && !$v.form.preferredTime.$invalid }]"
          >
            <option value="">Preferred time</option>
            <option value="Morning">Morning</option>
            <option value="Afternoon">Afternoon</option>
            <option value="Evening">Evening</option>
            <option value="Anytime">Anytime</option>
          </select>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.preferredTime.required && $v.form.preferredTime.$dirty">Preferred time is required</div>
        </div>
        <div class="field" v-show="form.bookAnAppointment">
          <select
              class="input-text-connect"
              v-model="form.timezone"
              @change="$v.form.timezone.$touch"
              :class="[{ 'error': $v.form.timezone.$invalid && $v.form.timezone.$dirty }, { 'success': $v.form.timezone.$dirty && !$v.form.timezone.$invalid }]"
          >
            <option value="">Your time zone</option>
            <option timeZoneId="1" gmtAdjustment="GMT-12:00" useDaylightTime="0" value="-(GMT-12:00) International Date Line West">(GMT-12:00) International Date Line West</option>
            <option timeZoneId="2" gmtAdjustment="GMT-11:00" useDaylightTime="0" value="-(GMT-11:00) Midway Island, Samoa">(GMT-11:00) Midway Island, Samoa</option>
            <option timeZoneId="3" gmtAdjustment="GMT-10:00" useDaylightTime="0" value="-(GMT-10:00) Hawaii">(GMT-10:00) Hawaii</option>
            <option timeZoneId="4" gmtAdjustment="GMT-09:00" useDaylightTime="1" value="-(GMT-09:00) Alaska">(GMT-09:00) Alaska</option>
            <option timeZoneId="5" gmtAdjustment="GMT-08:00" useDaylightTime="1" value="-(GMT-08:00) Pacific Time (US & Canada)">(GMT-08:00) Pacific Time (US & Canada)</option>
            <option timeZoneId="6" gmtAdjustment="GMT-08:00" useDaylightTime="1" value="-(GMT-08:00) Tijuana, Baja California">(GMT-08:00) Tijuana, Baja California</option>
            <option timeZoneId="7" gmtAdjustment="GMT-07:00" useDaylightTime="0" value="-(GMT-07:00) Arizona">(GMT-07:00) Arizona</option>
            <option timeZoneId="8" gmtAdjustment="GMT-07:00" useDaylightTime="1" value="-(GMT-07:00) Chihuahua, La Paz, Mazatlan">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
            <option timeZoneId="9" gmtAdjustment="GMT-07:00" useDaylightTime="1" value="-(GMT-07:00) Mountain Time (US & Canada)">(GMT-07:00) Mountain Time (US & Canada)</option>
            <option timeZoneId="10" gmtAdjustment="GMT-06:00" useDaylightTime="0" value="-(GMT-06:00) Central America">(GMT-06:00) Central America</option>
            <option timeZoneId="11" gmtAdjustment="GMT-06:00" useDaylightTime="1" value="-(GMT-06:00) Central Time (US & Canada)">(GMT-06:00) Central Time (US & Canada)</option>
            <option timeZoneId="12" gmtAdjustment="GMT-06:00" useDaylightTime="1" value="-(GMT-06:00) Guadalajara, Mexico City, Monterrey">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
            <option timeZoneId="13" gmtAdjustment="GMT-06:00" useDaylightTime="0" value="-(GMT-06:00) Saskatchewan">(GMT-06:00) Saskatchewan</option>
            <option timeZoneId="14" gmtAdjustment="GMT-05:00" useDaylightTime="0" value="-(GMT-05:00) Bogota, Lima, Quito, Rio Branco">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
            <option timeZoneId="15" gmtAdjustment="GMT-05:00" useDaylightTime="1" value="-(GMT-05:00) Eastern Time (US & Canada)">(GMT-05:00) Eastern Time (US & Canada)</option>
            <option timeZoneId="16" gmtAdjustment="GMT-05:00" useDaylightTime="1" value="-(GMT-05:00) Indiana (East)">(GMT-05:00) Indiana (East)</option>
            <option timeZoneId="17" gmtAdjustment="GMT-04:00" useDaylightTime="1" value="-(GMT-04:00) Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada)</option>
            <option timeZoneId="18" gmtAdjustment="GMT-04:00" useDaylightTime="0" value="-(GMT-04:00) Caracas, La Paz">(GMT-04:00) Caracas, La Paz</option>
            <option timeZoneId="19" gmtAdjustment="GMT-04:00" useDaylightTime="0" value="-(GMT-04:00) Manaus">(GMT-04:00) Manaus</option>
            <option timeZoneId="20" gmtAdjustment="GMT-04:00" useDaylightTime="1" value="-(GMT-04:00) Santiago">(GMT-04:00) Santiago</option>
            <option timeZoneId="21" gmtAdjustment="GMT-03:30" useDaylightTime="1" value="-3.(GMT-03:30) Newfoundland">(GMT-03:30) Newfoundland</option>
            <option timeZoneId="22" gmtAdjustment="GMT-03:00" useDaylightTime="1" value="-(GMT-03:00) Brasilia">(GMT-03:00) Brasilia</option>
            <option timeZoneId="23" gmtAdjustment="GMT-03:00" useDaylightTime="0" value="-(GMT-03:00) Buenos Aires, Georgetown">(GMT-03:00) Buenos Aires, Georgetown</option>
            <option timeZoneId="24" gmtAdjustment="GMT-03:00" useDaylightTime="1" value="-(GMT-03:00) Greenland">(GMT-03:00) Greenland</option>
            <option timeZoneId="25" gmtAdjustment="GMT-03:00" useDaylightTime="1" value="-(GMT-03:00) Montevideo">(GMT-03:00) Montevideo</option>
            <option timeZoneId="26" gmtAdjustment="GMT-02:00" useDaylightTime="1" value="-(GMT-02:00) Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
            <option timeZoneId="27" gmtAdjustment="GMT-01:00" useDaylightTime="0" value="-(GMT-01:00) Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
            <option timeZoneId="28" gmtAdjustment="GMT-01:00" useDaylightTime="1" value="-(GMT-01:00) Azores">(GMT-01:00) Azores</option>
            <option timeZoneId="29" gmtAdjustment="GMT+00:00" useDaylightTime="0" value="(GMT+00:00) Casablanca, Monrovia, Reykjavik">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
            <option timeZoneId="30" gmtAdjustment="GMT+00:00" useDaylightTime="1" value="(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
            <option timeZoneId="31" gmtAdjustment="GMT+01:00" useDaylightTime="1" value="(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
            <option timeZoneId="32" gmtAdjustment="GMT+01:00" useDaylightTime="1" value="(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
            <option timeZoneId="33" gmtAdjustment="GMT+01:00" useDaylightTime="1" value="(GMT+01:00) Brussels, Copenhagen, Madrid, Paris">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
            <option timeZoneId="34" gmtAdjustment="GMT+01:00" useDaylightTime="1" value="(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
            <option timeZoneId="35" gmtAdjustment="GMT+01:00" useDaylightTime="1" value="(GMT+01:00) West Central Africa">(GMT+01:00) West Central Africa</option>
            <option timeZoneId="36" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Amman">(GMT+02:00) Amman</option>
            <option timeZoneId="37" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Athens, Bucharest, Istanbul">(GMT+02:00) Athens, Bucharest, Istanbul</option>
            <option timeZoneId="38" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Beirut">(GMT+02:00) Beirut</option>
            <option timeZoneId="39" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Cairo">(GMT+02:00) Cairo</option>
            <option timeZoneId="40" gmtAdjustment="GMT+02:00" useDaylightTime="0" value="(GMT+02:00) Harare, Pretoria">(GMT+02:00) Harare, Pretoria</option>
            <option timeZoneId="41" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
            <option timeZoneId="42" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Jerusalem">(GMT+02:00) Jerusalem</option>
            <option timeZoneId="43" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Minsk">(GMT+02:00) Minsk</option>
            <option timeZoneId="44" gmtAdjustment="GMT+02:00" useDaylightTime="1" value="(GMT+02:00) Windhoek">(GMT+02:00) Windhoek</option>
            <option timeZoneId="45" gmtAdjustment="GMT+03:00" useDaylightTime="0" value="(GMT+03:00) Kuwait, Riyadh, Baghdad">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
            <option timeZoneId="46" gmtAdjustment="GMT+03:00" useDaylightTime="1" value="(GMT+03:00) Moscow, St. Petersburg, Volgograd">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
            <option timeZoneId="47" gmtAdjustment="GMT+03:00" useDaylightTime="0" value="(GMT+03:00) Nairobi">(GMT+03:00) Nairobi</option>
            <option timeZoneId="48" gmtAdjustment="GMT+03:00" useDaylightTime="0" value="(GMT+03:00) Tbilisi">(GMT+03:00) Tbilisi</option>
            <option timeZoneId="49" gmtAdjustment="GMT+03:30" useDaylightTime="1" value="3.(GMT+03:30) Tehran">(GMT+03:30) Tehran</option>
            <option timeZoneId="50" gmtAdjustment="GMT+04:00" useDaylightTime="0" value="(GMT+04:00) Abu Dhabi, Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
            <option timeZoneId="51" gmtAdjustment="GMT+04:00" useDaylightTime="1" value="(GMT+04:00) Baku">(GMT+04:00) Baku</option>
            <option timeZoneId="52" gmtAdjustment="GMT+04:00" useDaylightTime="1" value="(GMT+04:00) Yerevan">(GMT+04:00) Yerevan</option>
            <option timeZoneId="53" gmtAdjustment="GMT+04:30" useDaylightTime="0" value="4.(GMT+04:30) Kabul">(GMT+04:30) Kabul</option>
            <option timeZoneId="54" gmtAdjustment="GMT+05:00" useDaylightTime="1" value="(GMT+05:00) Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
            <option timeZoneId="55" gmtAdjustment="GMT+05:00" useDaylightTime="0" value="(GMT+05:00) Islamabad, Karachi, Tashkent">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
            <option timeZoneId="56" gmtAdjustment="GMT+05:30" useDaylightTime="0" value="5.(GMT+05:30) Sri Jayawardenapura">(GMT+05:30) Sri Jayawardenapura</option>
            <option timeZoneId="57" gmtAdjustment="GMT+05:30" useDaylightTime="0" value="5.(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
            <option timeZoneId="58" gmtAdjustment="GMT+05:45" useDaylightTime="0" value="5.(GMT+05:45) Kathmandu">(GMT+05:45) Kathmandu</option>
            <option timeZoneId="59" gmtAdjustment="GMT+06:00" useDaylightTime="1" value="(GMT+06:00) Almaty, Novosibirsk">(GMT+06:00) Almaty, Novosibirsk</option>
            <option timeZoneId="60" gmtAdjustment="GMT+06:00" useDaylightTime="0" value="(GMT+06:00) Astana, Dhaka">(GMT+06:00) Astana, Dhaka</option>
            <option timeZoneId="61" gmtAdjustment="GMT+06:30" useDaylightTime="0" value="6.(GMT+06:30) Yangon (Rangoon)">(GMT+06:30) Yangon (Rangoon)</option>
            <option timeZoneId="62" gmtAdjustment="GMT+07:00" useDaylightTime="0" value="(GMT+07:00) Bangkok, Hanoi, Jakarta">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
            <option timeZoneId="63" gmtAdjustment="GMT+07:00" useDaylightTime="1" value="(GMT+07:00) Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
            <option timeZoneId="64" gmtAdjustment="GMT+08:00" useDaylightTime="0" value="(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
            <option timeZoneId="65" gmtAdjustment="GMT+08:00" useDaylightTime="0" value="(GMT+08:00) Kuala Lumpur, Singapore">(GMT+08:00) Kuala Lumpur, Singapore</option>
            <option timeZoneId="66" gmtAdjustment="GMT+08:00" useDaylightTime="0" value="(GMT+08:00) Irkutsk, Ulaan Bataar">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
            <option timeZoneId="67" gmtAdjustment="GMT+08:00" useDaylightTime="0" value="(GMT+08:00) Perth">(GMT+08:00) Perth</option>
            <option timeZoneId="68" gmtAdjustment="GMT+08:00" useDaylightTime="0" value="(GMT+08:00) Taipei">(GMT+08:00) Taipei</option>
            <option timeZoneId="69" gmtAdjustment="GMT+09:00" useDaylightTime="0" value="(GMT+09:00) Osaka, Sapporo, Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
            <option timeZoneId="70" gmtAdjustment="GMT+09:00" useDaylightTime="0" value="(GMT+09:00) Seoul">(GMT+09:00) Seoul</option>
            <option timeZoneId="71" gmtAdjustment="GMT+09:00" useDaylightTime="1" value="(GMT+09:00) Yakutsk">(GMT+09:00) Yakutsk</option>
            <option timeZoneId="72" gmtAdjustment="GMT+09:30" useDaylightTime="0" value="9.(GMT+09:30) Adelaide">(GMT+09:30) Adelaide</option>
            <option timeZoneId="73" gmtAdjustment="GMT+09:30" useDaylightTime="0" value="9.(GMT+09:30) Darwin">(GMT+09:30) Darwin</option>
            <option timeZoneId="74" gmtAdjustment="GMT+10:00" useDaylightTime="0" value="(GMT+10:00) Brisbane">(GMT+10:00) Brisbane</option>
            <option timeZoneId="75" gmtAdjustment="GMT+10:00" useDaylightTime="1" value="(GMT+10:00) Canberra, Melbourne, Sydney">(GMT+10:00) Canberra, Melbourne, Sydney</option>
            <option timeZoneId="76" gmtAdjustment="GMT+10:00" useDaylightTime="1" value="(GMT+10:00) Hobart<">(GMT+10:00) Hobart</option>7
            <option timeZoneId="77" gmtAdjustment="GMT+10:00" useDaylightTime="0" value="(GMT+10:00) Guam, Port Moresby">(GMT+10:00) Guam, Port Moresby</option>
            <option timeZoneId="78" gmtAdjustment="GMT+10:00" useDaylightTime="1" value="(GMT+10:00) Vladivostok">(GMT+10:00) Vladivostok</option>
            <option timeZoneId="79" gmtAdjustment="GMT+11:00" useDaylightTime="1" value="(GMT+11:00) Magadan, Solomon Is., New Caledonia">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
            <option timeZoneId="80" gmtAdjustment="GMT+12:00" useDaylightTime="1" value="(GMT+12:00) Auckland, Wellington">(GMT+12:00) Auckland, Wellington</option>
            <option timeZoneId="81" gmtAdjustment="GMT+12:00" useDaylightTime="0" value="(GMT+12:00) Fiji, Kamchatka, Marshall Is.">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
            <option timeZoneId="82" gmtAdjustment="GMT+13:00" useDaylightTime="0" value="(GMT+13:00) Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
<!--            <option value="AEST">AEST (UTC+10)</option>-->
<!--            <option value="AEST">ACST (UTC+9.5)</option>-->
<!--            <option value="AEST">AWST (UTC+8)</option>-->
          </select>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.timezone.required && $v.form.timezone.$dirty">Time zone is required</div>
        </div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.mediaEnquiry">
              <div class="filler"></div>
            </div>
            <div class="select-none">Media Enquiry</div>
          </label>
        </div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.productInformation">
              <div class="filler"></div>
            </div>
            <div class="select-none">Product information</div>
          </label>
        </div>
        <div class="field" v-show="form.productInformation">
          <textarea v-model="form.productOfInterest" class="input-textarea-connect" placeholder="Include the products or varities you’re interested in…"></textarea>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.productOfInterest.required && $v.form.productOfInterest.$dirty">Please enter some comments</div>

        </div>

        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.generalEnquiry">
              <div class="filler"></div>
            </div>
            <div class="select-none">General Enquiry</div>
          </label>
        </div>
        <div class="field mt">
          <label for="comments">Comments</label>
          <textarea id="comments" v-model="form.comments" class="input-textarea-connect" placeholder="Include the products or varities you’re interested in…"></textarea>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.comments.required && $v.form.comments.$dirty">Please enter some comments</div>
        </div>


        <div class="form-heading">Your details</div>

        <div class="field">
          <label for="firstName">First name</label>
          <input
              id="firstName"
              type="text"
              name="firstName"
              placeholder="First name*"
              v-model="form.firstName"
              @input="$v.form.firstName.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.firstName.$invalid && $v.form.firstName.$dirty }, { 'success': $v.form.firstName.$dirty && !$v.form.firstName.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.firstName.required && $v.form.firstName.$dirty">First name is required</div>
        </div>
        <div class="field">
          <label for="lastName">Last name</label>
          <input
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Last name*"
              v-model="form.lastName"
              @input="$v.form.lastName.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.lastName.$invalid && $v.form.lastName.$dirty }, { 'success': $v.form.lastName.$dirty && !$v.form.lastName.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.lastName.required && $v.form.lastName.$dirty">Last name is required</div>
        </div>
        <div class="field">
          <label for="email">Email address</label>
          <input
              id="email"
              type="email"
              name="email"
              placeholder="Email address*"
              v-model="form.email"
              @input="$v.form.email.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.email.$invalid && $v.form.email.$dirty }, { 'success': $v.form.email.$dirty && !$v.form.email.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.email.required && $v.form.email.$dirty">Email address is required</div>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.email.email && $v.form.email.$dirty">Invalid email address</div>
        </div>
        <div class="field">
          <label for="role">Job title</label>
          <input
              id="role"
              type="text"
              name="role"
              placeholder="Occupation / Role*"
              v-model="form.role"
              @input="$v.form.role.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.role.$invalid && $v.form.role.$dirty }, { 'success': $v.form.role.$dirty && !$v.form.role.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.role.required && $v.form.role.$dirty">Occupation / Role is required</div>
        </div>
        <div class="field">
          <label for="company">Company</label>
          <input
              id="company"
              type="text"
              name="role"
              placeholder="Company*"
              v-model="form.company"
              @input="$v.form.company.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.company.$invalid && $v.form.company.$dirty }, { 'success': $v.form.company.$dirty && !$v.form.company.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.role.required && $v.form.role.$dirty">Occupation / Role is required</div>
        </div>
        <div class="field">
          <label for="country">Country</label>
          <select
              id="country"
              type="text"
              name="country"
              placeholder="Country*"
              v-model="form.country"
              @change="$v.form.country.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.country.$invalid && $v.form.country.$dirty }, { 'success': $v.form.country.$dirty && !$v.form.country.$invalid }]
                      ">
            <option value="">Country*</option>
            <option value="Afganistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bonaire">Bonaire</option>
            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Canary Islands">Canary Islands</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Channel Islands">Channel Islands</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos Island">Cocos Island</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote DIvoire">Cote DIvoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Curaco">Curacao</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands">Falkland Islands</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Ter">French Southern Ter</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Great Britain">Great Britain</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="Indonesia">Indonesia</option>
            <option value="India">India</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea North">Korea North</option>
            <option value="Korea Sout">Korea South</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macau">Macau</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Malawi">Malawi</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Midway Islands">Midway Islands</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Nambia">Nambia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherland Antilles">Netherland Antilles</option>
            <option value="Netherlands">Netherlands (Holland, Europe)</option>
            <option value="Nevis">Nevis</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau Island">Palau Island</option>
            <option value="Palestine">Palestine</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Phillipines">Philippines</option>
            <option value="Pitcairn Island">Pitcairn Island</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Republic of Montenegro">Republic of Montenegro</option>
            <option value="Republic of Serbia">Republic of Serbia</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="St Barthelemy">St Barthelemy</option>
            <option value="St Eustatius">St Eustatius</option>
            <option value="St Helena">St Helena</option>
            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
            <option value="St Lucia">St Lucia</option>
            <option value="St Maarten">St Maarten</option>
            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
            <option value="Saipan">Saipan</option>
            <option value="Samoa">Samoa</option>
            <option value="Samoa American">Samoa American</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Tahiti">Tahiti</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Erimates">United Arab Emirates</option>
            <option value="United States of America">United States of America</option>
            <option value="Uraguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Vatican City State">Vatican City State</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
            <option value="Wake Island">Wake Island</option>
            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
            <option value="Yemen">Yemen</option>
            <option value="Zaire">Zaire</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.country.required && $v.form.country.$dirty">Country is required</div>
        </div>


<!--        <div class="field">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.virtualWineryTours">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">Virtual winery tours</div>-->
<!--          </label>-->
<!--        </div>-->





<!--        <div class="field">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.salesEnquiry">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">Sales Enquiry</div>-->
<!--          </label>-->
<!--        </div>-->
<!--        <div class="field">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.other">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">Other</div>-->
<!--          </label>-->
<!--        </div>-->


<!--        <div class="field mt">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.iAgreeToAllowMyContactInformationToBeSentWithThisEnquiry">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">I agree to allow my contact information to be sent with this enquiry.</div>-->
<!--          </label>-->
<!--        </div>-->
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" class="opacity-0 absolute" v-model="form.sendACopyToMyEmailAddress">
              <div class="filler"></div>
            </div>
            <div class="select-none">Send a copy to my email address.</div>
          </label>
        </div>
        <div class="block text-label text-red-600 mt-4" v-if="!$v.form.enquiryType.required && $v.form.enquiryType.$dirty">Please select an enquiry type</div>

        <input type="hidden" v-model="form.enquiryType">
        <input type="hidden" v-model="form.winery">
        <input type="hidden" v-model="form.wineryId">

        <div class="submit">
          <button type="submit" class="btn-transparent btn-wide">Send</button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { Formio } from 'formiojs';
import Bus from '@/events/EventBus';
import * as dayjs from 'dayjs'
import Analytics from '@/analytics'
import Vue from 'vue'
import Vuelidate from 'vuelidate/dist/vuelidate.min'
import { required, email, requiredIf } from 'vuelidate/dist/validators.min'
Vue.use(Vuelidate)

export default {
  name: 'WineryConnect',
  components: {
    datetime: Datetime
  },
  props: {
    winery: {
      type: Object,
      default () {
        return {}
      }
    },
    product: {
      type: Object,
      default () {
        return null
      }
    }
  },
  computed: {
    tomorrow () {
      const d = new Date()
      const t = new Date(d)
      t.setDate(t.getDate() + 1)
      return t.toISOString()
    },
    preferredTime () {
      if (this.form.preferredTime.length) {
        return new Date(this.form.preferredTime).toLocaleTimeString('en-AU')
      }
      return null
    },
    preferredDate () {
      if (this.form.preferredDate.length) {
        return dayjs(this.form.preferredDate).format('DD/MM/YYYY')
      }
      return null
    },
    enquiryType () {
      let enq = []
      if (this.form.bookAnAppointment) {
        enq.push('Book a meeting')
      }
      if (this.form.generalEnquiry) {
        enq.push('General enquiry')
      }
      if (this.form.importerDistributerDetails) {
        enq.push('Importer / Distributor details')
      }
      if (this.form.mediaEnquiry) {
        enq.push('Media Enquiry')
      }
      if (this.form.priceListFobDetails) {
        enq.push('Price list / FOB details')
      }
      if (this.form.productInformation) {
        enq.push('Product information')
      }
      return enq.join(', ')
    }
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      company: {
        required
      },
      role: {
        required
      },
      country: {
        required
      },
      enquiryType: {
        required
      },
      preferredDate: {
        required: requiredIf((form) => {
          return form.bookAnAppointment
        })
      },
      preferredTime: {
        required: requiredIf((form) => {
          return form.bookAnAppointment
        })
      },
      timezone: {
        required: requiredIf((form) => {
          return form.bookAnAppointment
        })
      },
      productOfInterest: {
        required: requiredIf((form) => {
          return form.productInformation
        })
      },
      comments: {
        required
        // required: requiredIf((form) => {
        //   return form.generalEnquiry
        // })
      }
    }
  },
  data () {
    return {
      active: false,
      loading: false,
      form: {
        wineryId: this.winery.elements.winery_id.value,
        winery: this.winery.elements.name.value,
        wineryEmail: this.winery.elements.connect_key_contact_email.value,
        enquiryType: '',
        // virtualWineryTours: false,
        bookAnAppointment: false,
        productInformation: false,
        preferredDate: '',
        preferredTime: '',
        timezone: '',
        priceListFobDetails: false,
        importerDistributerDetails: false,
        mediaEnquiry: false,
        generalEnquiry: false,
        // salesEnquiry: false,
        // other: false,
        productOfInterest: '',
        comments: '',
        sendACopyToMyEmailAddress: false,
        firstName: this.$auth.user['https://australianwine.com/first_name'] ? this.$auth.user['https://australianwine.com/first_name'] : '',
        lastName: this.$auth.user['https://australianwine.com/last_name'] ? this.$auth.user['https://australianwine.com/last_name'] : '',
        email: this.$auth.user.email ? this.$auth.user.email : '',
        role: this.$auth.user['https://australianwine.com/job_role'] ? this.$auth.user['https://australianwine.com/job_role'] : '',
        company: this.$auth.user['https://australianwine.com/company_name'] ? this.$auth.user['https://australianwine.com/company_name'] : '',
        country: this.$auth.user['https://australianwine.com/country'] ? this.$auth.user['https://australianwine.com/country'] : ''
      }
    }
  },
  methods: {
    hide () {
      this.active = false
    },
    validateEnquiryType () {
      if (this.form.bookAnAppointment
          || this.form.generalEnquiry
          || this.form.importerDistributerDetails
          || this.form.mediaEnquiry
          || this.form.priceListFobDetails
          || this.form.productInformation) {
        this.form.enquiryType = 'valid'
      } else {
        this.form.enquiryType = ''
      }
    },
    validateForm () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    submitForm (e) {
      const self = this
      if (this.validateForm(e)) {
        self.loading = true
        this.$auth.getTokenSilently().then(() => {
          gtag('event', 'conversion', {
            'send_to': 'AW-741348585/mPWACK6TmMkCEOmpwOEC'
          });
          let formio = new Formio('https://bdakfyixwzkovjf.form.io/connect/submission');
          formio.saveSubmission({data: this.form}).then(() => {
            if (this.product) {
              Analytics.trackEvent('Connect submission', {
                category: 'ProductAction: Connect',
                label: this.product.elements.product_id.value,
                wineryId: this.winery.elements.winery_id.value,
                productId: this.product.elements.product_id.value,
                enquiryType: this.enquiryType
              })
            } else {
              Analytics.trackEvent('Connect submission', {
                category: 'WineryAction: Connect',
                label: this.winery.elements.winery_id.value,
                wineryId: this.winery.elements.winery_id.value,
                enquiryType: this.enquiryType
              })
            }
            self.loading = false
            self.hide()
            self.form.enquiryType = ''
            self.form.bookAnAppointment = false
            self.form.productInformation = false
            self.form.preferredDate = ''
            self.form.preferredTime = ''
            self.form.timezone = ''
            self.form.priceListFobDetails = false
            self.form.importerDistributerDetails = false
            self.form.mediaEnquiry = false
            self.form.generalEnquiry = false
            self.form.productOfInterest = false
            self.form.comments = ''
            self.form.sendACopyToMyEmailAddress = false
            self.$v.form.$reset()

            this.$toasted.show("Enquiry sent", {
              theme: "toasted-primary",
              position: "top-right",
              duration : 5000
            });
          });
        })
      }

    }
  },
  mounted() {
    Bus.$on('winery-connect', () => {
      this.active = true
    })
  },
  beforeDestroy() {
    Bus.$off('winery-connect')
  }
}
</script>

<style lang="scss">
.vdatetime-popup {
  font-family: 'Hellix';
  background-color: #222;
  color: #FFF;
  position: absolute;
}
.vdatetime-popup__header {
  background-color: #111;
}
.vdatetime-calendar__month__weekday > span > span, .vdatetime-calendar__month__day > span > span {
  border-radius: 0;
}
.vdatetime-calendar__month__day:hover > span > span {
  background-color: #444;
}
.vdatetime-calendar__month__day--disabled:hover > span > span {
  background-color: #222;
}
.vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
  background-color: #E8490F;
}

.vdatetime-popup__actions__button {
  border: 1px solid #E8490F;
  color: #FFF;

  &:hover {
    background-color: #E8490F;
    color: #FFF;
  }
}
.vdatetime-time-picker__item--selected {
  color: #E8490F;
}

.vdatetime-time-picker__list {
  scrollbar-color: #333 #111;
}
.vdatetime-time-picker__list::-webkit-scrollbar-track {
  background: #111;
}
.vdatetime-time-picker__list::-webkit-scrollbar-thumb {
  background: #333;
}

.vdatetime-popup__actions {
  padding: 10px;
  background-color: #111;
}
</style>

<style lang="scss" scoped>

.field {
  margin-top: 10px;

  &.mt {
    margin-top: 20px;
  }
}

.filler {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}

input:checked + .filler {
  display: block;
}

.winery-connect {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  bottom: 0;

  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(#000, 0.6);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .panel {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 450px;
    transition: all 0.3s ease;
    transform: translateX(100%);
    padding: 30px 20px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-width: 90%;

    @media(min-width: 768px)
    {
      padding: 50px 40px;
    }
  }

  &.active {
    .panel {
      transform: none;
    }
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

.winery-name {
  text-transform: uppercase;
  font-size: 14px;
  @media(min-width: 768px)
  {
    font-size: 18px;
  }
}

.heading {
  text-transform: uppercase;
  line-height: 1;
  font-size: 40px;
  margin-top: 10px;
  font-weight: bold;


  @media(min-width: 768px)
  {
    font-size: 68px;
  }
}

.intro {
  font-size: 14px;
  margin-top: 20px;
  @media(min-width: 768px)
  {
    margin-top: 30px;
  }
}

form {
  margin-top: 20px;
  @media(min-width: 768px)
  {
    margin-top: 30px;
  }
}

.form-heading {
  margin-top: 30px;
  font-weight: 600;
  @media(min-width: 768px)
  {
    margin-top: 30px;
  }
  font-size: 16px;
}

.submit {
  margin-top: 30px;
  text-align: right;
  @media(min-width: 768px)
  {
    margin-top: 40px;
  }
}
</style>
